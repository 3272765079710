import React, { useState, useEffect } from 'react';
import PageContent from '../../Components/PageModules/PageContent';
import Modal from '../../Components/Modal';
import Controls from '../../Components/Controls/Controls';
import { useForm, Form } from '../../Components/UseForm';
import { useSnackbar } from 'notistack';
import { useData } from '../../Context/Context';
import API from '../../libs/ServerAPIs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { LoadingButton } from '@mui/lab';
import Spinner from '../../Components/Spinner';
import {
    Box,
    Container,
    Button,
    InputAdornment,
    Grid,
    Divider,
    Card,
    CardActions,
    CardHeader,
    CardContent,
    IconButton,
    Paper,
    Tab,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { logPageVisit } from '../../libs/activityLog';

function Settings({ openModal, var_branch, closeModal }) {
    /* VARIABLE DECLERATION
    -------------------------------------------------------------------------------------*/
    const {
        userData,
        drawerOpen,
        frame,
        appTopBarTitleAsPerPage,
        refresh_data,
    } = useData(useData);
    const [, set_visibleFrame] = frame;
    const [user_details, setUserDetails] = userData;
    const [, set_app_top_bar_title] = appTopBarTitleAsPerPage;
    const [var_refresh_data, set_refresh_data] = refresh_data;
    const [var_tab_value, set_tab_value] = useState('notifications');
    const [varGetUpdatedAppSettings, setGetUpdatedAppSettings] =
        useState(false);
    const [
        var_save_email_notification_loading,
        set_save_email_notification_loading,
    ] = useState(false);
    const [var_status_disabled, set_status_disabled] = useState(true);
    const [var_compliance_pest, set_compliance_pest] = useState(true);
    const [var_loading, set_loading] = useState(false);
    const component = Settings.name;

    const initValues = {
        smtp: {
            hostName: '',
            portNumber: 0,
            userName: '',
            subjectPrefix: '',
            password: '',
            ssl: true,
        },
        pestPacApiConfig: {
            pestPacClientId: '',
            pestPacClientSecret: '',
            pestPacApiKey: '',
            pestPacCompanyKey: '',
            pestPacUser: '',
            pestPacPassword: '',
        },
        googleMapApiKeyConfig: '',
        spragueServiceDeskEmail: '',
        spragueInfoDeskEmail: '',
        mssqlServer: '',
        mssqlDatabase: '',
        mssqlPort: 0,
        mssqlUser: '',
        mssqlPassword: '',
        mssqlSSL: false,
        expired: false,
        expiring: false,
        missing: false,
        branches: [],
        expired_global: false,
        compliance_pest: false,
        missing_sds: false,
        recent_pest: false,
        deactivate_logbook: false,
        pest_branches: [],
        consolidated_report: 'daily',
    };

    const {
        var_values,
        set_values,
        var_errors,
        set_errors,
        handleInputChange,
    } = useForm(initValues);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    //const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const [showSecretKeys, setShowSecretKeys] = useState({
        smtpPassword: false,
        googleMaps: false,
        pestPacClientId: false,
        pestPacClientSecret: false,
        pestPacPassword: false,
        pestPacApiKey: false,
        mssqlPassword: false,
    });

    const [, set_drawer_open] = drawerOpen;

    /* EVENT LISTENERS
    -------------------------------------------------------------------------------------*/
    useEffect(() => {
        if (user_details.type !== 'sprague') {
            logPageVisit('User Settings', '', component, user_details);
        }
        set_app_top_bar_title('Settings');
    }, []);

    useEffect(() => {
        if (!var_tab_value || var_tab_value === '') {
            set_tab_value('notifications');
        }
    }, [user_details]);

    useEffect(() => {
        if (var_tab_value === 'notifications') {
            var converted_branches = [];
            var converted_pestbranches = [];
            if (
                user_details?.notificationSettings?.includes('siteBranches') &&
                JSON.parse(user_details?.notificationSettings).siteBranches !==
                'all'
            ) {
                for (let i = 0; i < var_branch.length; i++) {
                    var temp_branches = JSON.parse(
                        user_details?.notificationSettings
                    )
                        ?.siteBranches?.replace(/[{}]/g, '')
                        .split(',');

                    if (temp_branches) {
                        for (let j = 0; j < temp_branches.length; j++) {
                            if (temp_branches[j] * 1 === var_branch[i].value) {
                                converted_branches = [
                                    ...converted_branches,
                                    var_branch[i],
                                ];
                            }
                        }
                    }
                }
            } else {
                for (let i = 0; i < var_branch.length; i++) {
                    converted_branches = [...converted_branches, var_branch[i]];
                }
            }
            if (
                user_details?.notificationSettings?.includes('pestBranches') &&
                JSON.parse(user_details?.notificationSettings).pestBranches !==
                'all'
            ) {
                for (let i = 0; i < var_branch.length; i++) {
                    var temp_pestbranches = JSON.parse(
                        user_details?.notificationSettings
                    )
                        .pestBranches?.replace(/[{}]/g, '')
                        .split(',');
                    if (temp_pestbranches) {
                        for (let j = 0; j < temp_pestbranches.length; j++) {
                            if (
                                temp_pestbranches[j] * 1 ===
                                var_branch[i].value
                            ) {
                                converted_pestbranches = [
                                    ...converted_pestbranches,
                                    var_branch[i],
                                ];
                            }
                        }
                    }
                }
            } else {
                for (let i = 0; i < var_branch.length; i++) {
                    converted_pestbranches = [
                        ...converted_pestbranches,
                        var_branch[i],
                    ];
                }
            }
            set_values({
                ...var_values,
                expired:
                    (user_details &&
                        user_details?.notificationSettings?.includes('siteExpired')
                        ? JSON.parse(user_details?.notificationSettings)
                            .siteExpired
                        : false) || false,
                missing:
                    (user_details &&
                        user_details?.notificationSettings?.includes('siteMissing')
                        ? JSON.parse(user_details?.notificationSettings)
                            .siteMissing
                        : false) || false,
                expiring:
                    (user_details &&
                        user_details?.notificationSettings?.includes('siteExpiring')
                        ? JSON.parse(user_details?.notificationSettings)
                            .siteExpiring
                        : false) || false,
                branches:
                    (user_details &&
                        user_details?.notificationSettings?.includes('siteBranches')
                        ? converted_branches
                        : []) || [],
                pest_branches:
                    (user_details &&
                        user_details?.notificationSettings?.includes('pestBranches')
                        ? converted_pestbranches
                        : []) || [],
                expired_global:
                    (user_details &&
                        user_details?.notificationSettings?.includes(
                            'globalExpired'
                        )
                        ? JSON.parse(user_details?.notificationSettings)
                            .globalExpired
                        : false) || false,
                deactivate_logbook:
                    (user_details &&
                        user_details?.notificationSettings?.includes(
                            'locationsDeactivatingSoon'
                        )
                        ? JSON.parse(user_details?.notificationSettings)
                            .locationsDeactivatingSoon
                        : false) || false,
                compliance_pest:
                    (user_details &&
                        user_details?.notificationSettings?.includes('pestOverdue')
                        ? JSON.parse(user_details?.notificationSettings)
                            .pestOverdue
                        : false) || false,
                missing_sds:
                    (user_details &&
                        user_details?.notificationSettings?.includes('SDSMissing')
                        ? JSON.parse(user_details?.notificationSettings)
                            .SDSMissing
                        : false) || false,
                recent_pest:
                    (user_details &&
                        user_details?.notificationSettings?.includes('pestAdded')
                        ? JSON.parse(user_details?.notificationSettings)
                            .pestAdded
                        : false) || false,
                consolidated_report:
                    (user_details &&
                        user_details?.notificationSettings?.includes('notify')
                        ? JSON.parse(user_details?.notificationSettings).notify
                        : 'daily') || 'daily',
            });
        }
    }, [var_tab_value, openModal, var_branch, var_loading]);

    useEffect(() => {
        if (var_values.expired || var_values.missing || var_values.expiring) {
            set_status_disabled(false);
        } else {
            set_status_disabled(true);
        }
        if (var_values.compliance_pest) {
            set_compliance_pest(false);
        } else {
            set_compliance_pest(true);
        }
    }, [var_values]);

    useEffect(() => {
        set_visibleFrame(true);
        set_drawer_open(false);
        if (openModal) {
            if (user_details.isAdmin || user_details.isContentManager) {
                getAppSettings();
            }
            return () => {
                API.PendingRequestsManager.abortRequest(
                    component,
                    'getAppSettings'
                );
                API.PendingRequestsManager.abortRequest(
                    component,
                    'handleConfirmSmtpDefaultSettings'
                );
                set_values(initValues);
            };
        }
    }, [varGetUpdatedAppSettings, openModal]);

    function handleSecretKeyVisibility(fieldToBeToggled) {
        if (fieldToBeToggled in showSecretKeys) {
            setShowSecretKeys({
                ...showSecretKeys,
                [fieldToBeToggled]: !showSecretKeys[fieldToBeToggled],
            });
        }
    }

    /* ASYNC FUNCTIONS
    -------------------------------------------------------------------------------------*/
    async function getAppSettings() {
        set_loading(true);
        const requestUrl = '/api/v1/app-setting';
        API.PendingRequestsManager.registerRequest(
            component,
            'getAppSettings',
            requestUrl
        );
        const appSettings = await API.get(requestUrl).then((res) => {
            if (!res.error) {
                var converted_branches = [];
                var converted_pestbranches = [];
                if (
                    user_details?.notificationSettings?.includes(
                        'siteBranches'
                    ) &&
                    JSON.parse(user_details?.notificationSettings)
                        .siteBranches !== 'all'
                ) {
                    for (let i = 0; i < var_branch.length; i++) {
                        var temp_branches = JSON.parse(
                            user_details?.notificationSettings
                        )
                            ?.siteBranches?.replace(/[{}]/g, '')
                            .split(',');

                        if (temp_branches) {
                            for (let j = 0; j < temp_branches.length; j++) {
                                if (
                                    temp_branches[j] * 1 ===
                                    var_branch[i].value
                                ) {
                                    converted_branches = [
                                        ...converted_branches,
                                        var_branch[i],
                                    ];
                                }
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < var_branch.length; i++) {
                        converted_branches = [
                            ...converted_branches,
                            var_branch[i],
                        ];
                    }
                }
                if (
                    user_details?.notificationSettings?.includes(
                        'pestBranches'
                    ) &&
                    JSON.parse(user_details?.notificationSettings)
                        .pestBranches !== 'all'
                ) {
                    for (let i = 0; i < var_branch.length; i++) {
                        var temp_pestbranches = JSON.parse(
                            user_details?.notificationSettings
                        )
                            .pestBranches?.replace(/[{}]/g, '')
                            .split(',');
                        if (temp_pestbranches) {
                            for (let j = 0; j < temp_pestbranches.length; j++) {
                                if (
                                    temp_pestbranches[j] * 1 ===
                                    var_branch[i].value
                                ) {
                                    converted_pestbranches = [
                                        ...converted_pestbranches,
                                        var_branch[i],
                                    ];
                                }
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < var_branch.length; i++) {
                        converted_pestbranches = [
                            ...converted_pestbranches,
                            var_branch[i],
                        ];
                    }
                }
                const smtpSettings = res.find(
                    (appSetting) => appSetting.name === 'SMTP'
                ).value;
                const pestPacApiConfig = res.find(
                    (appSetting) => appSetting.name === 'PestPacApiConfig'
                ).value;
                const googleMapApiKey = res.find(
                    (appSetting) => appSetting.name === 'GoogleMapApiKey'
                ).value;
                const spragueServiceDeskEmail = res.find(
                    (appSetting) =>
                        appSetting.name === 'SpragueServiceDeskEmail'
                ).value;
                const spragueInfoDeskEmail = res.find(
                    (appSetting) => appSetting.name === 'SpragueInfoDeskEmail'
                ).value;
                const mssqlSettings = res.find(
                    (appSetting) => appSetting.name === 'MSSQL'
                )?.value;
                set_values({
                    smtp: {
                        hostName: smtpSettings.host,
                        portNumber: smtpSettings.port,
                        userName: smtpSettings.user,
                        password: smtpSettings.pass,
                        subjectPrefix: smtpSettings.subject_prefix,
                        ssl: smtpSettings.ssl,
                    },
                    pestPacApiConfig: {
                        pestPacClientId: pestPacApiConfig.clientId,
                        pestPacClientSecret: pestPacApiConfig.clientSecret,
                        pestPacCompanyKey: pestPacApiConfig.companyKey,
                        pestPacUser:
                            pestPacApiConfig.username ??
                            pestPacApiConfig.user ??
                            pestPacApiConfig.user ??
                            '',
                        pestPacPassword: pestPacApiConfig.password,
                        pestPacApiKey: pestPacApiConfig.apiKey,
                    },
                    googleMapApiKeyConfig: googleMapApiKey,
                    spragueServiceDeskEmail: spragueServiceDeskEmail,
                    spragueInfoDeskEmail: spragueInfoDeskEmail,
                    mssqlServer: (mssqlSettings && mssqlSettings.server) || '',
                    mssqlDatabase:
                        (mssqlSettings && mssqlSettings.database) || '',
                    mssqlPort: (mssqlSettings && mssqlSettings.port) || 1433,
                    mssqlUser: (mssqlSettings && mssqlSettings.user) || '',
                    mssqlPassword:
                        (mssqlSettings && mssqlSettings.password) || '',
                    mssqlSSL: (mssqlSettings && mssqlSettings.ssl) || false,
                    expired:
                        (user_details &&
                            user_details?.notificationSettings?.includes(
                                'siteExpired'
                            )
                            ? JSON.parse(user_details?.notificationSettings)
                                .siteExpired
                            : false) || false,
                    missing:
                        (user_details &&
                            user_details?.notificationSettings?.includes(
                                'siteMissing'
                            )
                            ? JSON.parse(user_details?.notificationSettings)
                                .siteMissing
                            : false) || false,
                    expiring:
                        (user_details &&
                            user_details?.notificationSettings?.includes(
                                'siteExpiring'
                            )
                            ? JSON.parse(user_details?.notificationSettings)
                                .siteExpiring
                            : false) || false,
                    // branches: (user_details && user_details?.notificationSettings?.includes('siteBranches')? converted_branches : []) || [],
                    expired_global:
                        (user_details &&
                            user_details?.notificationSettings?.includes(
                                'globalExpired'
                            )
                            ? JSON.parse(user_details?.notificationSettings)
                                .globalExpired
                            : false) || false,
                    deactivate_logbook:
                        (user_details &&
                            user_details?.notificationSettings?.includes(
                                'locationsDeactivatingSoon'
                            )
                            ? JSON.parse(user_details?.notificationSettings)
                                .locationsDeactivatingSoon
                            : false) || false,
                    compliance_pest:
                        (user_details &&
                            user_details?.notificationSettings?.includes(
                                'pestOverdue'
                            )
                            ? JSON.parse(user_details?.notificationSettings)
                                .pestOverdue
                            : false) || false,
                    // pest_branches: (user_details && user_details?.notificationSettings?.includes('pestBranches') ?  converted_pestbranches :[]) || [],
                    missing_sds:
                        (user_details &&
                            user_details?.notificationSettings?.includes(
                                'SDSMissing'
                            )
                            ? JSON.parse(user_details?.notificationSettings)
                                .SDSMissing
                            : false) || false,
                    recent_pest:
                        (user_details &&
                            user_details?.notificationSettings?.includes(
                                'pestAdded'
                            )
                            ? JSON.parse(user_details?.notificationSettings)
                                .pestAdded
                            : false) || false,
                    consolidated_report:
                        (user_details &&
                            user_details?.notificationSettings?.includes('notify')
                            ? JSON.parse(user_details?.notificationSettings)
                                .notify
                            : 'daily') || 'daily',
                });
            }
            set_loading(false);
        });
        return appSettings;
    }

    /* EVENT FUNCTIONS
    -------------------------------------------------------------------------------------*/
    function handleFormInputChange(e, formName = '') {
        const { name, value } = e.target;
        if (formName === '') {
            set_values({
                ...var_values,
                [name]: value,
            });
        } else {
            set_values({
                ...var_values,
                [formName]: {
                    ...var_values[formName],
                    [name]: value,
                },
            });
        }
    }

    function showSnackbar(variant, message) {
        enqueueSnackbar(message, {
            variant: variant,
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center',
            },
        });
        setTimeout(closeSnackbar, 2000);
    }

    function handleMSSQLSettingsUpdate() {
        let temp = { ...var_errors };
        let isValid = true;
        if (!var_values.mssqlServer || var_values.mssqlServer === '') {
            temp.mssqlServer = 'Please enter MSSQL server name';
            isValid = false;
        }
        if (!var_values.mssqlDatabase || var_values.mssqlDatabase === '') {
            temp.mssqlDatabase = 'Please enter MSSQL database name';
            isValid = false;
        }
        if (!var_values.mssqlPort || Number.isNaN(var_values.mssqlPort * 1)) {
            temp.mssqlPort = 'Please enter valid MSSQL port number';
            isValid = false;
        }
        if (!var_values.mssqlUser || var_values.mssqlUser === '') {
            temp.mssqlUser = 'Please enter MSSQL user';
            isValid = false;
        }
        if (!var_values.mssqlPassword || var_values.mssqlPassword === '') {
            temp.mssqlPassword = 'Please enter MSSQL user password';
            isValid = false;
        }
        set_errors(temp);
        // if no errors then send update request
        if (isValid) {
            API.put('/api/v1/app-setting/MSSQL', {
                value: {
                    server: var_values.mssqlServer,
                    port: var_values.mssqlPort,
                    ssl: var_values.mssqlSSL,
                    user: var_values.mssqlUser,
                    password: var_values.mssqlPassword,
                    database: var_values.mssqlDatabase,
                },
            }).then((response) => {
                let responseType = 'error';
                if (response.success) {
                    responseType = 'success';
                    setGetUpdatedAppSettings(!varGetUpdatedAppSettings);
                }
                showSnackbar(responseType, response.message);
            });
        }
    }

    function handleGoogleMapsKeyUpdate() {
        API.put('/api/v1/app-setting/GoogleMapApiKey', {
            value: var_values.googleMapApiKeyConfig,
        }).then((response) => {
            let responseType = 'error';
            if (response.success) {
                responseType = 'success';
            }
            showSnackbar(responseType, response.message);
        });
    }

    function handlePestPacApiConfigUpdate() {
        let pestPacApiConfigErrors = validatePestPacApiConfigFields(
            Object.keys(var_values.pestPacApiConfig)
        );
        set_errors(pestPacApiConfigErrors);
        if (Object.keys(pestPacApiConfigErrors).length === 0) {
            API.put('/api/v1/app-setting/PestPacApiConfig', {
                value: {
                    clientId: var_values.pestPacApiConfig.pestPacClientId,
                    clientSecret:
                        var_values.pestPacApiConfig.pestPacClientSecret,
                    companyKey: var_values.pestPacApiConfig.pestPacCompanyKey,
                    username: var_values.pestPacApiConfig.pestPacUser,
                    password: var_values.pestPacApiConfig.pestPacPassword,
                    apiKey: var_values.pestPacApiConfig.pestPacApiKey,
                },
            }).then((response) => {
                let responseType = 'error';
                if (response.success) {
                    responseType = 'success';
                }
                showSnackbar(responseType, response.message);
            });
        }
    }

    function validatePestPacApiConfigFields(fieldList = []) {
        let field_errors = {};
        fieldList.forEach((field) => {
            if (
                field in var_values.pestPacApiConfig &&
                var_values.pestPacApiConfig[field] &&
                var_values.pestPacApiConfig[field].toString().trim() === ''
            ) {
                field_errors = {
                    ...field_errors,
                    [field]: `This field must be provided with some value`,
                };
            }
        });

        return field_errors;
    }

    const validateEmail = (fieldValues = var_values) => {
        let temp = { ...var_errors };
        let isValid = true;
        if (
            var_values.branches?.length === 0 &&
            (var_values.expired || var_values.missing || var_values.expiring) &&
            !user_details.isFieldUser
        ) {
            temp.branches = 'Please select branch';
            isValid = false;
        }
        if (
            fieldValues.pest_branches?.length === 0 &&
            fieldValues.compliance_pest &&
            !user_details.isFieldUser
        ) {
            temp.pest_branches = 'Please select pest branch';
            isValid = false;
        }
        set_errors({
            ...temp,
        });
        return isValid;
    };

    function handleTabChange(event, newValue) {
        set_tab_value(newValue);
    }

    function handleSaveEmailNotification() {
        if (validateEmail()) {
            set_save_email_notification_loading(true);
            let email_branches = '';
            let email_pestbranches = '';

            if (
                var_values.branches?.length === 0 ||
                (!var_values.expired &&
                    !var_values.missing &&
                    !var_values.expiring)
            ) {
                email_branches = 'null';
            } else if (var_values.branches?.length === var_branch?.length) {
                email_branches = 'all';
            } else {
                for (let i = 0; i < var_values.branches?.length; i++) {
                    email_branches += var_values.branches[i]?.value + ',';
                }
            }
            if (
                var_values.pest_branches?.length === 0 ||
                !var_values.compliance_pest
            ) {
                email_pestbranches = 'null';
            } else if (
                var_values.pest_branches?.length === var_branch?.length
            ) {
                email_pestbranches = 'all';
            } else {
                for (let i = 0; i < var_values.pest_branches?.length; i++) {
                    email_pestbranches +=
                        var_values.pest_branches[i]?.value + ',';
                }
            }

            API.put(
                `/api/v1/user/${user_details?.userID}/notifications-setting`,
                user_details.isFieldUser
                    ? {
                        locationsDeactivatingSoon:
                            var_values.deactivate_logbook,
                        pestAdded: var_values.recent_pest,
                        pestOverdue: var_values.compliance_pest,
                        notify: var_values.consolidated_report,
                    }
                    : {
                        locationsDeactivatingSoon:
                            var_values.deactivate_logbook,
                        siteExpired: var_values.expired,
                        siteMissing: var_values.missing,
                        siteExpiring: var_values.expiring,
                        siteBranches: email_branches?.replace(/,\s*$/, ''),
                        globalExpired: var_values.expired_global,
                        pestOverdue: var_values.compliance_pest,
                        pestBranches: email_pestbranches?.replace(
                            /,\s*$/,
                            ''
                        ),
                        SDSMissing: var_values.missing_sds,
                        notify: var_values.consolidated_report,
                    }
            ).then((res) => {
                set_save_email_notification_loading(false);
                if (!res.error) {
                    set_refresh_data(!var_refresh_data);
                    API.get('/api/v1/auth').then((userInfo) => {
                        setUserDetails(userInfo);
                    });
                }
                showSnackbar(res.error ? 'error' : 'success', res.message);
            });
        }
    }

    /* RENDER APP
    -------------------------------------------------------------------------------------*/
    return (
        <Modal
            title="Notifications"
            openModal={openModal}
            closeModal={closeModal}
            width="md"
            dialogActions={
                <>
                    <Button onClick={closeModal}>Cancel</Button>
                </>
            }
        >
            {var_loading && <Spinner />}
            <TabContext value={var_tab_value}>
                <Paper
                    square
                    sx={{
                        p: { xs: '0', sm: '0', md: '0 24px' },
                        boxSizing: 'border-box',
                        display: 'flex',
                        justifyContent: 'space-between',
                        minHeight: { xs: '0', md: '69px' },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'flex-end',
                            maxWidth: '100%',
                            '& .MuiButtonBase-root.MuiTabScrollButton-root': {
                                backgroundColor: 'rgba(0,0,0,0.12)',
                            },
                        }}
                    >
                        <TabList
                            onChange={handleTabChange}
                            variant="scrollable"
                            allowScrollButtonsMobile
                        >
                            {/* {user_details?.isAdmin && 
                                    <Tab value="SMTP" label="SMTP Settings" />
                                    } */}
                            {/* <Tab value="API-config" label="API Configuration" />
                                    <Tab value="MSSQL" label="Microsoft SQL Server" /> */}
                            <Tab value="notifications" label="Notifications" />
                        </TabList>
                    </Box>
                </Paper>
                <PageContent>
                    <Container maxWidth="lg">
                        {/* {user_details?.isAdmin && 
                                <TabPanel value="SMTP" sx={{ p: '0' }}>
                                    <Form>
                                        <Card>

                                            <CardHeader title="SMTP Settings" />

                                            <Divider />

                                            <CardContent sx={{ p: '24px' }}>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Controls.Input
                                                            name="hostName"
                                                            label="Host"
                                                            value={var_values.smtp.hostName}
                                                            error={var_errors.hostName}
                                                            onChange={(e) => handleFormInputChange(e, 'smtp')}
                                                            type="text"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Controls.Input
                                                            name="portNumber"
                                                            label="Port"
                                                            value={var_values.smtp.portNumber}
                                                            error={var_errors.portNumber}
                                                            onChange={(e) => handleFormInputChange(e, 'smtp')}
                                                            type="text"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Controls.Input
                                                            name="userName"
                                                            label="Email/Username"
                                                            value={var_values.smtp.userName}
                                                            error={var_errors.userName}
                                                            onChange={(e) => handleFormInputChange(e, 'smtp')}
                                                            type="text"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Controls.Input
                                                            name="password"
                                                            label="Password"
                                                            value={var_values.smtp.password}
                                                            error={var_errors.password}
                                                            onChange={(e) => handleFormInputChange(e, 'smtp')}
                                                            type={showSecretKeys.smtpPassword ? "text" : "password"}
                                                            InputProps={ {
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={() => handleSecretKeyVisibility('smtpPassword')}
                                                                                edge="end"
                                                                            >
                                                                                {showSecretKeys.smtpPassword ? <VisibilityOff /> : <Visibility />}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    )
                                                                }
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Controls.Checkbox
                                                            name="ssl"
                                                            label="SSL"
                                                            value={var_values.smtp.ssl}
                                                            onChange={(e) => handleFormInputChange(e, 'smtp')}
                                                            type="checkbox"
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Controls.Input
                                                            name="subjectPrefix"
                                                            label="Subject Prefix"
                                                            value={var_values.smtp.subjectPrefix}
                                                            error={var_errors.subjectPrefix}
                                                            onChange={(e) => handleFormInputChange(e, 'smtp')}
                                                            type="text"
                                                        />
                                                    </Grid>
                                                </Grid>

                                            </CardContent>

                                            <Divider />
                                            <CardActions
                                                sx={{
                                                    px: '12px',
                                                    justifyContent: 'flex-end',
                                                    '@media (max-width:420px)': {
                                                        display: 'block',
                                                        '& .MuiButton-root': {
                                                            width: '100%',
                                                            margin: '0',
                                                            mt: '8px'
                                                        }
                                                    }
                                                }}
                                            >
                                                <Button onClick={() => setOpenSmtpDefaultSettingConfirmationModal(true)}>Reset To Default</Button>
                                                <Button variant="contained" color="primary" onClick={() => setOpenSendTestEmailModal(true)} >Send Test Email</Button>
                                                <Button variant="contained" color="secondary" onClick={handleSmtpSettingsUpdate}>Save</Button>
                                            </CardActions>
                                        </Card>
                                    </Form>
                                    <ModalSendTestEmail openModal={varOpenSendTestEmailModal} closeModal={() => setOpenSendTestEmailModal(false)}></ModalSendTestEmail>
                                    <ModalConfirm
                                        title={'Reset to default configuration'}
                                        text={'Are you sure to want to reset SMTP configuration to default?'}
                                        openModal={varOpenSmtpDefaultSettingConfirmationModal}
                                        closeModal={() => setOpenSmtpDefaultSettingConfirmationModal(false)}
                                        type="error"
                                        loading={varSmtpDefaultSettingsUpdate}
                                        handleConfirm={handleConfirmSmtpDefaultSettings}
                                    />
                                </TabPanel>
                                } */}
                        <TabPanel value="API-config" sx={{ p: '0' }}>
                            <Form>
                                <Card sx={{ mt: '24px' }}>
                                    <CardHeader title="Google Map API Key" />

                                    <Divider />

                                    <CardContent sx={{ p: '24px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="googleMapApiKeyConfig"
                                                    label="Google Map API"
                                                    value={
                                                        var_values.googleMapApiKeyConfig
                                                    }
                                                    onChange={(e) =>
                                                        handleFormInputChange(e)
                                                    }
                                                    type={
                                                        showSecretKeys.googleMaps
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        handleSecretKeyVisibility(
                                                                            'googleMaps'
                                                                        )
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {showSecretKeys.googleMaps ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <Divider />
                                    <CardActions
                                        sx={{
                                            px: '12px',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleGoogleMapsKeyUpdate}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Form>
                            <Form>
                                <Card sx={{ mt: '24px' }}>
                                    <CardHeader title="Pestpac API Configuration" />

                                    <Divider />

                                    <CardContent sx={{ p: '24px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="pestPacClientId"
                                                    value={
                                                        var_values
                                                            .pestPacApiConfig
                                                            .pestPacClientId
                                                    }
                                                    error={
                                                        var_errors.pestPacClientId
                                                    }
                                                    label="Client ID"
                                                    onChange={(e) =>
                                                        handleFormInputChange(
                                                            e,
                                                            'pestPacApiConfig'
                                                        )
                                                    }
                                                    type={
                                                        showSecretKeys.pestPacClientId
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        handleSecretKeyVisibility(
                                                                            'pestPacClientId'
                                                                        )
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {showSecretKeys.pestPacClientId ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="pestPacClientSecret"
                                                    value={
                                                        var_values
                                                            .pestPacApiConfig
                                                            .pestPacClientSecret
                                                    }
                                                    error={
                                                        var_errors.pestPacClientSecret
                                                    }
                                                    label="API Settings"
                                                    onChange={(e) =>
                                                        handleFormInputChange(
                                                            e,
                                                            'pestPacApiConfig'
                                                        )
                                                    }
                                                    type={
                                                        showSecretKeys.pestPacClientSecret
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        handleSecretKeyVisibility(
                                                                            'pestPacClientSecret'
                                                                        )
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {showSecretKeys.pestPacClientSecret ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="pestPacApiKey"
                                                    value={
                                                        var_values
                                                            .pestPacApiConfig
                                                            .pestPacApiKey
                                                    }
                                                    error={
                                                        var_errors.pestPacApiKey
                                                    }
                                                    label="API Key"
                                                    onChange={(e) =>
                                                        handleFormInputChange(
                                                            e,
                                                            'pestPacApiConfig'
                                                        )
                                                    }
                                                    type={
                                                        showSecretKeys.pestPacApiKey
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        handleSecretKeyVisibility(
                                                                            'pestPacApiKey'
                                                                        )
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {showSecretKeys.pestPacApiKey ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="pestPacCompanyKey"
                                                    value={
                                                        var_values
                                                            .pestPacApiConfig
                                                            .pestPacCompanyKey
                                                    }
                                                    error={
                                                        var_errors.pestPacCompanyKey
                                                    }
                                                    label="Company Key"
                                                    onChange={(e) =>
                                                        handleFormInputChange(
                                                            e,
                                                            'pestPacApiConfig'
                                                        )
                                                    }
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="pestPacUser"
                                                    value={
                                                        var_values
                                                            .pestPacApiConfig
                                                            .pestPacUser
                                                    }
                                                    error={
                                                        var_errors.pestPacUser
                                                    }
                                                    label="User"
                                                    onChange={(e) =>
                                                        handleFormInputChange(
                                                            e,
                                                            'pestPacApiConfig'
                                                        )
                                                    }
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="pestPacPassword"
                                                    value={
                                                        var_values
                                                            .pestPacApiConfig
                                                            .pestPacPassword
                                                    }
                                                    error={
                                                        var_errors.pestPacPassword
                                                    }
                                                    label="Password"
                                                    onChange={(e) =>
                                                        handleFormInputChange(
                                                            e,
                                                            'pestPacApiConfig'
                                                        )
                                                    }
                                                    type={
                                                        showSecretKeys.pestPacPassword
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        handleSecretKeyVisibility(
                                                                            'pestPacPassword'
                                                                        )
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {showSecretKeys.pestPacPassword ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <Divider />

                                    <CardActions
                                        sx={{
                                            px: '12px',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={
                                                handlePestPacApiConfigUpdate
                                            }
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Form>
                        </TabPanel>
                        <TabPanel value="MSSQL" sx={{ p: '0' }}>
                            <Form>
                                <Card>
                                    <CardHeader title="PestPac Microsoft SQL Server Settings" />
                                    <Divider />

                                    <CardContent sx={{ p: '24px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="mssqlServer"
                                                    label="Server Name/IP"
                                                    value={
                                                        var_values.mssqlServer
                                                    }
                                                    error={
                                                        var_errors.mssqlServer
                                                    }
                                                    onChange={handleInputChange}
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="mssqlDatabase"
                                                    label="Database Name"
                                                    value={
                                                        var_values.mssqlDatabase
                                                    }
                                                    error={
                                                        var_errors.mssqlDatabase
                                                    }
                                                    onChange={handleInputChange}
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="mssqlPort"
                                                    label="Port"
                                                    value={var_values.mssqlPort}
                                                    error={var_errors.mssqlPort}
                                                    onChange={handleInputChange}
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="mssqlUser"
                                                    label="Username"
                                                    value={var_values.mssqlUser}
                                                    error={var_errors.mssqlUser}
                                                    onChange={handleInputChange}
                                                    type="text"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controls.Input
                                                    name="mssqlPassword"
                                                    label="Password"
                                                    value={
                                                        var_values.mssqlPassword
                                                    }
                                                    error={
                                                        var_errors.mssqlPassword
                                                    }
                                                    onChange={handleInputChange}
                                                    type={
                                                        showSecretKeys.mssqlPassword
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={() =>
                                                                        handleSecretKeyVisibility(
                                                                            'mssqlPassword'
                                                                        )
                                                                    }
                                                                    edge="end"
                                                                >
                                                                    {showSecretKeys.mssqlPassword ? (
                                                                        <VisibilityOff />
                                                                    ) : (
                                                                        <Visibility />
                                                                    )}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Controls.Checkbox
                                                    name="mssqlSSL"
                                                    label="SSL"
                                                    value={var_values.mssqlSSL}
                                                    onChange={handleInputChange}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <Divider />
                                    <CardActions
                                        sx={{
                                            px: '12px',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={handleMSSQLSettingsUpdate}
                                        >
                                            Save
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Form>
                        </TabPanel>
                        {user_details && (
                            <TabPanel value="notifications" sx={{ p: '0' }}>
                                <Form>
                                    <Card>
                                        <CardHeader
                                            title="Email Notifications"
                                            subheader="Manage your email notifications on the following activities."
                                        />
                                        <Divider />
                                        <CardContent sx={{ p: '24px' }}>
                                            <Grid container spacing={2}>
                                                <Card
                                                    sx={{
                                                        width: '100%',
                                                        padding: '16px 8px',
                                                        mt: '8px',
                                                    }}
                                                >
                                                    <CardHeader title="Logbooks" />
                                                    <Grid
                                                        item
                                                        sx={{ pl: '16px' }}
                                                        xs={12}
                                                    >
                                                        <Controls.Checkbox
                                                            name="deactivate_logbook"
                                                            label="Deactivate Logbook in 45 Days"
                                                            value={
                                                                var_values.deactivate_logbook
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                        />
                                                    </Grid>
                                                </Card>
                                                {(user_details.isAdmin ||
                                                    user_details.isContentManager) && (
                                                        <Card
                                                            sx={{
                                                                width: '100%',
                                                                padding: '16px 8px',
                                                                mt: '8px',
                                                            }}
                                                        >
                                                            <CardHeader title="Site Specific Documents" />
                                                            <Grid
                                                                item
                                                                sx={{ pl: '16px' }}
                                                                xs={12}
                                                            >
                                                                <Controls.Checkbox
                                                                    name="expired"
                                                                    label="Expired"
                                                                    value={
                                                                        var_values.expired
                                                                    }
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                />
                                                                <Controls.Checkbox
                                                                    name="missing"
                                                                    label="Missing"
                                                                    value={
                                                                        var_values.missing
                                                                    }
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                />
                                                                <Controls.Checkbox
                                                                    name="expiring"
                                                                    label="Expiring Soon"
                                                                    value={
                                                                        var_values.expiring
                                                                    }
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                sx={{ pl: '16px' }}
                                                                xs={12}
                                                            >
                                                                <Controls.MultiSelect
                                                                    label="Branches"
                                                                    value={
                                                                        var_values.branches ||
                                                                        []
                                                                    }
                                                                    error={
                                                                        var_errors.branches
                                                                    }
                                                                    selectAllIntegrate={
                                                                        true
                                                                    }
                                                                    name="branches"
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                    disabled={
                                                                        var_status_disabled
                                                                    }
                                                                    options={
                                                                        var_branch ||
                                                                        []
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Card>
                                                    )}
                                                {user_details.isAdmin && (
                                                    <Card
                                                        sx={{
                                                            width: '100%',
                                                            padding: '16px 8px',
                                                            mt: '8px',
                                                        }}
                                                    >
                                                        <CardHeader title="Global Documents" />
                                                        <Grid
                                                            item
                                                            sx={{ pl: '16px' }}
                                                            xs={12}
                                                        >
                                                            <Controls.Checkbox
                                                                name="expired_global"
                                                                label="Expired"
                                                                value={
                                                                    var_values.expired_global
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                            />
                                                        </Grid>
                                                    </Card>
                                                )}

                                                {!user_details.isFieldUser && (
                                                    <Card
                                                        sx={{
                                                            width: '100%',
                                                            padding: '16px 8px',
                                                            mt: '8px',
                                                        }}
                                                    >
                                                        <CardHeader title="Pest Sightings" />
                                                        <Grid
                                                            item
                                                            sx={{ pl: '16px' }}
                                                            xs={12}
                                                        >
                                                            <Controls.Checkbox
                                                                name="compliance_pest"
                                                                label="Overdue Pest Sighting"
                                                                value={
                                                                    var_values.compliance_pest
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                            />
                                                        </Grid>
                                                        {(user_details.isAdmin ||
                                                            user_details.isContentManager) && (
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    sx={{
                                                                        pt: '16px',
                                                                        pl: '16px',
                                                                    }}
                                                                >
                                                                    <Controls.MultiSelect
                                                                        label="Branches"
                                                                        value={
                                                                            var_values.pest_branches ||
                                                                            []
                                                                        }
                                                                        error={
                                                                            var_errors.pest_branches
                                                                        }
                                                                        selectAllIntegrate={
                                                                            true
                                                                        }
                                                                        name="pest_branches"
                                                                        onChange={
                                                                            handleInputChange
                                                                        }
                                                                        disabled={
                                                                            var_compliance_pest
                                                                        }
                                                                        options={
                                                                            var_branch ||
                                                                            []
                                                                        }
                                                                    />
                                                                </Grid>
                                                            )}
                                                    </Card>
                                                )}
                                                {user_details.isAdmin && (
                                                    <Card
                                                        sx={{
                                                            width: '100%',
                                                            padding: '16px 8px',
                                                            mt: '8px',
                                                        }}
                                                    >
                                                        <CardHeader title="SDS & Label Documents" />
                                                        <Grid
                                                            item
                                                            sx={{ pl: '16px' }}
                                                            xs={12}
                                                        >
                                                            <Controls.Checkbox
                                                                name="missing_sds"
                                                                label="Missing Documents"
                                                                value={
                                                                    var_values.missing_sds
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                            />
                                                        </Grid>
                                                    </Card>
                                                )}
                                                <Card
                                                    sx={{
                                                        width: '100%',
                                                        padding: '16px 8px',
                                                        mt: '8px',
                                                    }}
                                                >
                                                    <CardHeader title="Consolidated Report" />
                                                    {/* <Grid item xs={12} sx={{ pt:"16px"}}>
                                                            
                                                        </Grid> */}
                                                    <Grid
                                                        item
                                                        sx={{ pl: '16px' }}
                                                        xs={12}
                                                    >
                                                        <Controls.RadioGroup
                                                            name="consolidated_report"
                                                            value={
                                                                var_values.consolidated_report
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            error={
                                                                var_errors.consolidated_report
                                                            }
                                                            items={[
                                                                {
                                                                    id: 'daily',
                                                                    title: 'Daily',
                                                                },
                                                                {
                                                                    id: 'weekly',
                                                                    title: 'Weekly',
                                                                },
                                                                {
                                                                    id: 'monthly',
                                                                    title: 'Monthly',
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        </CardContent>
                                        <Divider />
                                        <CardActions
                                            sx={{
                                                px: '12px',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            <LoadingButton
                                                onClick={
                                                    handleSaveEmailNotification
                                                }
                                                variant="contained"
                                                color="secondary"
                                                loading={
                                                    var_save_email_notification_loading
                                                }
                                            >
                                                Save
                                            </LoadingButton>
                                        </CardActions>
                                    </Card>
                                </Form>
                            </TabPanel>
                        )}
                    </Container>
                </PageContent>
            </TabContext>
        </Modal>
    );
}

export default Settings;
